import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { denormalize } from 'normalizr';
import ParameterSchema from './schema';
import selectEntities from '../../../modules/entities/selectors';
import EK from '../../keys';

const selectNormalizedParameters = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.PARAMETERS.state)
);

export const selectCurrentParametersCount = () => createSelector(
  selectNormalizedParameters(),
  params => params.toList().size
);

export const selectParametersOptions = () => createSelector(
  selectEntities(),
  selectNormalizedParameters(),
  (entities, parameters) => denormalize(parameters, [ParameterSchema], entities).toList().toArray()
);

const parameterFilterForm = formValueSelector(`filters.${EK.PARAMETERS.state}`);

export const selectCurrentFilteredParameters = () => createSelector(
  selectEntities(),
  selectSidebarIsFiltering(),
  selectNormalizedParameters(),
  state => parameterFilterForm(state, 'name', 'valueType', 'description'),
  (entities, isFiltering, parameters, query) => denormalize(isFiltering ? parameters.filter(parameter => parameter.doesMatchQuery(query)) : parameters, [ParameterSchema], entities)
);