import generateAPHTypes from '../../../utils/constants/generateAPHTypes';
const resource = 'NOTE_CONTENTS';

export const fetchAllNoteContentsTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const createNoteContentTypes = generateAPHTypes(resource, 'CREATE');

export const saveNoteContentsTypes = generateAPHTypes(resource, 'COLLECTION');

export const editNoteContentTypes = generateAPHTypes(resource, 'EDIT');

export const deleteNoteContentTypes = generateAPHTypes(resource, 'DELETE');