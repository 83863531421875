import { useState, useRef, useEffect } from 'react';

export const useOnCellEditComplete = (data, convertToType, editable, useDropdowns=false) => {
  const [ editedRows, setEditedRows ] = useState([]);
  const [ currentEditingRow, setCurrentEditingRow ] = useState();
  const [ isEditing, setIsEditing ] = useState(false);

  // entity row start tools
  const removeItemFromRows = (originalRow) => {
    let _rows = [...editedRows];
    const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    _rows.splice(rowsItemIndex, 1);
    setEditedRows(_rows);
  };

  const handleRowAction = (item, iconName) => {
    const originalItem = data.find((itm) => item.id == itm.id);
    let _rows = [...editedRows];
    switch (iconName) {
    case 'undo':
      removeItemFromRows(originalItem);
      break;
    case 'delete':
      removeItemFromRows(originalItem);
      _rows.push({ ...(convertToType(originalItem)), isDeleted: true, isEdited: true });
      setEditedRows(_rows);
      break;
    }
  };

  // Cell Editing Management
  const onBeforeCellEditShow = (e, customField=null) => {
    if (customField) {
      e[customField] && setCurrentEditingRow(editedRows && editedRows.find((item) => item.id == e[customField].id) || convertToType(e[customField]));
      return;
    }

    const curRow = editedRows && editedRows.find((item) => item.id == e.rowData.id) || convertToType(e.rowData);
    setCurrentEditingRow(curRow);
    setIsEditing(true);
  };

  /**
   * Updates the current editing row with the new value for the specified field.
   * If a custom onChange function is provided, it will be used to update the value.
   *
   * @param {*} value - The new value to set for the field.
   * @param {string} field - The field to update with the new value.
   * @param {function} [customOnChange=false] - Optional custom function to handle the value change.
   */
  const onCellChange = (value, field, customOnChange=false) => {
    setCurrentEditingRow(_editedRow => {
      if(customOnChange) {
        return customOnChange(value);
      } else {
        return { ..._editedRow, [field]: value };
      }
    });
  };

  const onCellEditCompleteRef = useRef(null);

  /**
   * manages the state of 'editedRows' to track changes to cells, optimized for easy use with PrimeReact datagrid's 'onCellEditComplete' callback
   * @param {import('primereact/column').ColumnEvent} e I think this is the right data type?
   * @returns null, edits state to reflect changes to the cell
   */
  const onCellEditComplete = (e, customField=null) => {

    setIsEditing(false);
    const originalRow = customField && e[customField] ?
      data.find((item) => item.id == e[customField].id) :
      data.find((item) => item.id == e.rowData.id);

    // Ensure we have originalRow data
    if (!originalRow) {
      return null; // Return null or indicate error
    }

    // Use the currentEditingRow state which should have been updated by onCellChange
    let _editedRow = convertToType(currentEditingRow);

    if (!editable || !_editedRow) { // Added check for _editedRow existence
      return null;
    }

    // Prevent further action if ID doesn't match original - sanity check
    if (_editedRow.id != originalRow.id) {
      return null;
    }

    // Check if deleted flag is set
    if (_editedRow.isDeleted) {
      // Process deletion (remove from editedRows if present)
      setEditedRows(prevRows => prevRows.filter(r => r.id !== originalRow.id));
      return _editedRow; // Return the row marked as deleted
    }

    let _rows = [...editedRows];
    const currentEditedIndex = _rows.findIndex(item => item.id == originalRow.id);

    // Compare the potentially modified _editedRow with the original data
    const stringifiedEdited = JSON.stringify(
      convertToType({
        ..._editedRow,
        isEdited: undefined,
        isCreated: undefined,
        isDeleted: undefined
      })
    ); // Exclude flags for comparison
    const stringifiedOriginal = JSON.stringify(convertToType(originalRow));

    if (stringifiedEdited === stringifiedOriginal) {
      // Value was changed back to original - remove from editedRows
      if (currentEditedIndex !== -1) {
        _rows.splice(currentEditedIndex, 1);
        setEditedRows(_rows);
      }

      // IMPORTANT: Return the _editedRow which holds the *final* (original) value
      return { ..._editedRow, isEdited: false, isCreated: originalRow.id.includes('NEW_ROW') };
    }

    // If we reach here, the value is different from the original
    _editedRow.isEdited = true; // Mark as edited
    if (originalRow.id.includes('NEW_ROW')) {
      _editedRow.isCreated = true; // Keep created flag if it was a new row

      if (currentEditedIndex !== -1) {
        _rows[currentEditedIndex] = _editedRow;
      } else {
        _rows.push(_editedRow);
      }

    } else {
      // Existing item modification
      if (currentEditedIndex !== -1) {
        _rows[currentEditedIndex] = _editedRow;
      } else {
        _rows.push(_editedRow);
      }
    }

    setEditedRows(_rows);

    // Return the final state of the edited row
    return _editedRow;
  };

  const onDropdownCompleteRef = useRef(null);

  /**
   * not just for dropdowns, different form of this.onCellEditComplete optimized for use on cells without access to the PrimeReact.DataGrid.onCellEditComplete
   * must be used through the onDropdownCompleteRef, as state is consumed and may not be accurate without ref usage
   * @param {any} newValue the individual value to replace the original with
   * @param {string} field the field in the object to replace the value of
   * @param {any} originalEditedRow the original row that needs to be changed
   * @returns null, but edits state to reflect necessary changes
   */
  const onDropdownComplete = (newValue, field, originalEditedRow ) => {
    setIsEditing(false);
    const originalRow = data.find((item) => item.id == originalEditedRow.id);
    let _editedRow = convertToType(originalEditedRow);
    _editedRow[field] = newValue;

    if (!editable || _editedRow.isDeleted || _editedRow.id != originalRow.id ) {
      // user does not have permission to edit ( 'user' level permissions or invalid license )
      return;
    }
    let _rows = [...editedRows];

    // if the item is the same as the original item, remove it from the rows
    if (JSON.stringify(convertToType(_editedRow)) ===
      JSON.stringify(convertToType(originalRow))) {
      const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
      if (rowsItemIndex != -1) {
        _rows.splice(rowsItemIndex, 1);
        setEditedRows(_rows);
        return;
      }
      return;
    }

    // if it is a new item, add it to editedRows
    if (originalRow.id.includes('NEW_ROW')) {
      let editedRow = _rows.find(item => item.id == originalRow.id);
      if (editedRow) { // row has already been created/edited
        editedRow[field] = _editedRow[field];
      } else { // row needs to be added to the 'created rows' list
        _editedRow.isEdited = true;
        _editedRow.isCreated = true;
        _rows.push(_editedRow);
      }
      setEditedRows(_rows);
      return;
    }

    // if it is an existing item, add it to editedRows
    let currentRowItem = _rows && _rows.find(item => item.id == originalRow.id);
    if (currentRowItem) {
      //if the item is already in editedRows, edit it
      currentRowItem[field] = _editedRow[field];
    } else {
      //if it is not, then add it to them
      _editedRow.isEdited = true;
      _rows.push(_editedRow);
    }
    setEditedRows(_rows);
  };

  useEffect(() => {
    //ref to solve value persistence (updates the function to use current values on state change)
    onCellEditCompleteRef.current = onCellEditComplete;
    if (useDropdowns) {
      onDropdownCompleteRef.current = onDropdownComplete;
    }
  }, [currentEditingRow, data]);

  if (useDropdowns) {
    return [
      editedRows,
      () => setEditedRows([]),
      currentEditingRow,
      isEditing,
      onBeforeCellEditShow,
      onCellChange,
      onCellEditCompleteRef,
      handleRowAction,
      onDropdownCompleteRef,
      setCurrentEditingRow
    ];
  }

  return [
    editedRows,
    () => setEditedRows([]),
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditCompleteRef,
    handleRowAction
  ];
};