
import React, { useEffect, useMemo } from 'react';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import Text from '../../../../components/common/Text';
import { Dropdown } from 'primereact/dropdown';

import { processFetchAllUnits } from '../../../../entities/Synchronize/Units/actions';

// selectors
import { useSelector } from 'react-redux';
import { selectUnits } from '../../../../entities/Synchronize/Units/selectors';
import { createSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import { PARAMETER_VALUE_TYPE_OPTIONS } from '../../../../entities/Standardize/Parameters/model';
import MultiTypeCellEditor from '../../../../components/grid/MultiTypeCellEditor';

const mapStateToProps = createSelector(
  selectUnits(),
  units => ({
    units: units?.toList().toArray() || []
  })
);

/**
 * Renders a dialog for editing or creating a parameter in the src/routes/Standardize/Parameters page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.currentEditingRow - The current editing row object.
 * @param {boolean} props.editable - Indicates if the dialog is editable.
 * @param {Function} props.handleEditedRowChange - The function to handle changes in the edited row.
 * @param {boolean} [props.isCreateDialogOpen=false] - Indicates if the create dialog is open.
 * @param {boolean} [props.isCopyDialogOpen=false] - Indicates if the copy dialog is open.
 * @param {boolean} props.isDialogOpen - Indicates if the dialog is open.
 * @param {boolean} [props.openDeleteDialog=false] - Indicates if the delete dialog is open.
 * @param {Function} props.saveAction - The function to save the changes.
 * @param {Function} props.cancelDialogs - The function to cancel the dialogs.
 * @param {Object} props.validation - The validation object.
 * @returns {JSX.Element} The rendered dialog component.
 */
export const ParameterDialog = ({
  currentEditingRow,
  editable,
  handleEditedRowChange,
  isCreateDialogOpen = false,
  isCopyDialogOpen = false,
  isDialogOpen,
  openDeleteDialog = false,
  saveAction,
  cancelDialogs,
  validation
}) => {
  const { units } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const getDialogHeader = useMemo(() => {
    let header = 'Create New Parameter';

    if (isCopyDialogOpen) {
      header = `Copy '${currentEditingRow.name}'`;
    }
    if (!isCreateDialogOpen) {
      header = `Edit '${currentEditingRow.name}'`;
    }
    return header;
  }, [isDialogOpen]);

  const GetDefaultValueEditor = useMemo(() => MultiTypeCellEditor(currentEditingRow, handleEditedRowChange, units), [currentEditingRow.valueType, currentEditingRow.defaultValue, isDialogOpen]);

  useEffect(() => {
    dispatch(processFetchAllUnits());
  }, []);

  return (
    <Dialog
      visible={editable && isDialogOpen}
      style={{ width: '75%' }}
      header={() => getDialogHeader}
      footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)}
      modal
      className='p-fluid'
      closable={false}
    >
      <div>
        <label>Name</label>
        <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
      </div>
      <div>
        <label>Value Type</label>
        <Dropdown
          value={currentEditingRow.valueType}
          options={PARAMETER_VALUE_TYPE_OPTIONS}
          onChange={(e) => handleEditedRowChange(e.target.value, 'valueType')}
          className={!validation.valueType && 'p-invalid'}
        />
      </div>
      {GetDefaultValueEditor}
      <div>
        <label>Description</label>
        <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')} />
      </div>
    </Dialog>
  );
};

export const DeleteParameterDialog = ({
  editable,
  isDeleteDialogOpen,
  currentEditingRow,
  setIsDeleteDialogOpen,
  deleteAction
}) => (
  <Dialog
    visible={editable && isDeleteDialogOpen}
    style={{ width: '32rem' }}
    header={`Delete Parameter '${currentEditingRow.name}'`}
    footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)}
    closable={false}
  >
    <div>
      <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
      <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
      <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
    </div>
  </Dialog>
);
