import React, { useEffect, useMemo, useState, useRef } from 'react';

import { compose } from 'redux';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import sanitizeHtml from 'sanitize-html';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import injectReducer from '../../../../utils/reducers/injectReducer';
import injectSaga from '../../../../utils/sagas/injectSaga';
import saga from '../../../../components/grid/MultiValueNoteBodyEditor/sagas';
import reducer from '../../../../components/grid/MultiValueNoteBodyEditor/reducers';
import { NOTE_CONTENT_ADD_SUCCESS } from '../../../../components/grid/MultiValueNoteBodyEditor/constants';

import Text from '../../../../components/common/Text';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import { ALLOWANCES } from '../../../../entities/Standardize/Notes/model';
import { NOTE_TYPES_OPTIONS } from '../../../../entities/Standardize/Notes/model';
import MultiValueNoteBodyEditor, { replaceSpanTags, prepareNoteBodyForSave } from '../../../../components/grid/MultiValueNoteBodyEditor';

import { processCreateNoteContents, processFetchAllNoteContents, processDeleteNoteContent, processUpdateNoteContent } from '../../../../entities/Standardize/NoteContents/actions';
import { selectCurrentNoteContents, selectNoteContentsState } from '../../../../entities/Standardize/NoteContents/selectors';
import { processFetchAllParameters } from '../../../../entities/Standardize/Parameters/actions';

import { processSaveNotes } from '../../../../entities/Standardize/Notes/actions';
import { selectCurrentNote } from '../../../../entities/Standardize/Notes/selectors';


const LoadingSkeletonTemplate = () =>
  <>
    <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} count={2} />
    <Skeleton style={{ height: '6rem', marginBottom: '1rem' }} />
    <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
  </>;

const CNCModal = ({ cancelDialog, isOpen, createNoteContent }) => {
  const [description, setDescription] = useState('Select a content type to create');

  return (
    <Dialog
      visible={isOpen}
      style={{ width: '32rem' }}
      header='Create New Note Content'
      footer={() => DialogFooter(false, false, false, false, cancelDialog)}
      closable={false}
    >
      <div style={{ textAlign: 'center', width: '100%', marginBottom: '1rem' }}>
        {description}
      </div>
      <Flex flexDirection='column' justifyContent='center' style={{ width: '100%' }}>
        <Button
          primary={true}
          style={{ margin: '2.5px', width: '100%' }}
          onClick={() => createNoteContent('text')}
          onMouseEnter={() => setDescription('A sequence of characters and can contain letters, numbers, symbols and spaces. example: \'Hello, World!\'')}
        >
          Text
        </Button>
        <Button
          primary={true}
          style={{ margin: '2.5px', width: '100%' }}
          onClick={() => createNoteContent('parameter')}
          onMouseEnter={() => setDescription('Choose from a dropdown of your Standardize Parameters. (see parameters page for more information on parameters)')}
        >
          Parameter
        </Button>
        <Button
          primary={true}
          style={{ margin: '2.5px', width: '100%' }}
          onClick={() => createNoteContent('date')}
          onMouseEnter={() => setDescription('Selectable date value')}
        >
          Date
        </Button>
        <Button
          primary={true}
          style={{ margin: '2.5px', width: '100%' }}
          onClick={() => createNoteContent('list')}
          onMouseEnter={() => setDescription('A list of selectable text values, editable in the web app and appearing as a dropdown in the Creo app')}
        >
          List
        </Button>
        <Button
          primary={true}
          style={{ margin: '2.5px', width: '100%' }}
          onClick={() => createNoteContent('number')}
          onMouseEnter={() => setDescription('example: 3.14159')}
        >
          Number
        </Button>
      </Flex>
    </Dialog>
  );
};

const mapStateToProps = (noteId) => createSelector(
  selectCurrentNote(noteId),
  selectCurrentNoteContents(noteId),
  selectNoteContentsState(),
  (
    note,
    noteContents,
    { noteContentCreated, newNoteContent, noteContentsLoading }
  ) => ({
    note,
    noteContents,
    noteContentCreated,
    newNoteContent,
    noteContentsLoading
  })
);

function NotesDialog({
  currentEditingRow,
  editable,
  handleEditedRowChange,
  isCreateDialogOpen = false,
  isCopyDialogOpen = false,
  isDialogOpen,
  openDeleteDialog = false,
  saveAction,
  cancelDialogs,
  validation,
  updateCurrentEditingRow
}) {
  const dispatch = useDispatch();
  const {
    note,
    noteContents,
    noteContentCreated,
    newNoteContent,
    noteContentsLoading,
  } = useSelector(mapStateToProps(currentEditingRow.id)); // ultimately notes need to be changed in the api to return note contents as well

  const [noteContentsToUpdate, setNoteContentsToUpdate] = useState([]);
  // Create the ref to store the last selection range
  const lastSelectionRangeRef = useRef(null);

  const deleteNoteContent = (id) => {
    dispatch(processDeleteNoteContent(id));
  };

  const createNoteContent = (type) => {
    dispatch(processCreateNoteContents(currentEditingRow.id, { noteId: currentEditingRow.id, contentType: type }));
  };

  const replaceSpanTagsWithId = (text) => {
    // This regex matches the updated span format with additional attributes
    const spanRegex = /<span(?:[^>]*?)id="([a-f0-9-]+)"(?:[^>]*?)class="note-content-tag ncid[a-f0-9-]+"(?:[^>]*?)>.*?<\/span>/g;
    return text.replace(spanRegex, (_, id) => `\${${id}}`);
  };

  const onSave = () => {
    let newBody = document.getElementById('editable-div-container').innerHTML;

    // Sanitize HTML to allow only necessary tags
    newBody = sanitizeHtml(newBody, {
      allowedTags: ['div', 'br', 'span'], allowedAttributes: {
        '*': ['id', 'class', 'style'],
        'span': ['id', 'class', 'style', 'contenteditable', 'data-content-index', 'data-placeholder']
      }
    });

    // Process HTML to convert to plain text with newlines
    newBody = newBody.replaceAll('<div><br /></div>', '\n'); // replace explicit newlines
    newBody = newBody.replaceAll(/<div>([\s\S]*?)<\/div>/g, '\n$1'); // replace divs with \n
    newBody = newBody.replaceAll('<br />', '\n'); // replace <br /> with \n
    newBody = newBody.replaceAll('<br>', '\n'); // replace <br> with \n

    // Make sure all spans are replaced with their IDs - use both methods to ensure coverage
    newBody = prepareNoteBodyForSave(newBody);
    newBody = replaceSpanTagsWithId(newBody);

    // Only remove the first newline if it exists
    if (newBody.startsWith('\n')) {
      newBody = newBody.substring(1); // remove false first newline
    }

    // Decode common HTML entities
    const decodeEntities = (text) => {
      const entities = {
        '&nbsp;': ' ',
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': '\'',
        '&apos;': '\'',
        '&#x2F;': '/'
      };

      let result = text;
      for (const [entity, char] of Object.entries(entities)) {
        result = result.replaceAll(entity, char);
      }
      return result;
    };

    newBody = decodeEntities(newBody);

    // Handle note content updates
    noteContentsToUpdate.length > 0 && noteContentsToUpdate.forEach(nc => {
      if (nc.isDeleted) {
        // Handle deletion (unchanged)
        newBody = newBody.replace(`\${${nc.id}}`, '');
        dispatch(processDeleteNoteContent(nc.id));

      } else if (nc.isEdited || nc.isCreated) { // Only dispatch update if actually changed
        // Construct the specific payload based on contentType
        let updatePayload = {};

        if (nc.contentType === 'list') {
          // For lists, send content_value as a JSON array string - such as "['one', 'two', 'three']"
          // and content_default_value as the selected string value.
          let listValuesArray = [];
          if (Array.isArray(nc.contentValue)) {
            // Extract the 'value' property from each object in the array
            listValuesArray = nc.contentValue.map(v => v?.value ?? v); // Handle potential objects vs strings

          } else if (typeof nc.contentValue === 'string' && nc.contentValue.trim() !== '') {
            // Attempt to parse if it's a comma-separated string already (less ideal)
            listValuesArray = nc.contentValue.split(',').map(s => s.trim());
          }

          updatePayload = {
            // Stringify the array of values to create a valid JSON array string
            content_value: JSON.stringify(listValuesArray),
            content_default_value: nc.contentDefaultValue // This should already be the string value like "one"
          };

        } else if (nc.contentType === 'text' || nc.contentType === 'number') {
          // For text/number, ONLY send content_default_value
          updatePayload = {
            content_default_value: nc.contentDefaultValue
          };

        } else {
          // Handle other types if necessary, or log a warning
          updatePayload = {
            content_default_value: nc.contentDefaultValue
          };
        }

        // Dispatch the update with the correctly structured payload
        dispatch(processUpdateNoteContent(nc.id, updatePayload));
      }
    });

    saveAction(newBody); // Save the main note body
    setNoteContentsToUpdate([]); // Clear the list of changes
  };

  // no copy api method for this one
  const getDialogHeader = useMemo(() => {
    if (isCreateDialogOpen) {
      return 'Create Note';
    }
    return `Edit '${currentEditingRow.name}'`;
  }, [isDialogOpen]);

  useEffect(() => {
    currentEditingRow.id && dispatch(processFetchAllNoteContents(currentEditingRow.id));
    dispatch(processFetchAllParameters());
  }, [currentEditingRow.id]);

  useEffect(() => {
    if (currentEditingRow.id && note.noteBody != currentEditingRow.noteBody) {
      const currentNonEntityRow = {
        id: note.id,
        name: note.name,
        noteBody: note.noteBody,
        description: note.description,
        noteType: note.noteType,
      };
      updateCurrentEditingRow(currentNonEntityRow);
    }
  }, [note]);

  return (
    <Dialog
      visible={editable && isDialogOpen}
      style={{ width: '75%' }}
      header={getDialogHeader}
      footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, onSave, cancelDialogs)}
      modal
      className='p-fluid'
      closable={false}
    >
      {!noteContentsLoading &&
        <div>
          <div>
            <h3>Note Details</h3>
            <label>Name</label>
            <InputText
              keyfilter={ALLOWANCES.NAME}
              value={currentEditingRow.name}
              onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
              className={!validation.name && 'p-invalid'}
            />
          </div>
          <div>
            <label>Note Type</label>
            <Dropdown
              optionLabel='label'
              value={currentEditingRow.noteType}
              options={NOTE_TYPES_OPTIONS}
              onChange={(e) => handleEditedRowChange(e.value, 'noteType')}
              filter
            />
          </div>
          <div>
            <label>Description</label>
            <InputText
              value={currentEditingRow.description}
              onChange={(e) => handleEditedRowChange(e.target.value, 'description')}
            />
          </div>
          <div>
            <h3>Note Body</h3>
            <MultiValueNoteBodyEditor
              isDialogOpen={isDialogOpen}
              note={{ ...currentEditingRow, noteContents: noteContents }}

              noteContentCreated={noteContentCreated}
              newNoteContent={newNoteContent}

              deleteNoteContent={deleteNoteContent}
              createNoteContent={createNoteContent}
              resetNoteContentCreated={() => dispatch({ type: NOTE_CONTENT_ADD_SUCCESS })}
              handleEditedRowChange={handleEditedRowChange}
              updateNoteContents={setNoteContentsToUpdate}
              // Pass the ref down
              lastSelectionRangeRef={lastSelectionRangeRef}
            />
          </div>
        </div> ||
        <LoadingSkeletonTemplate />}
    </Dialog>
  );
}

export function DeleteNoteDialog({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) {

  return (
    <Dialog
      visible={editable && isDeleteDialogOpen}
      style={{ width: '32rem' }}
      header={`Delete Note '${currentEditingRow.name}'`}
      footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)}
      closable={false}
    >
      <div>
        <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
        <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
      </div>
    </Dialog>
  );
}

const enhance = compose(
  injectReducer({ key: 'noteContentsModal', reducer }),
  injectSaga({ key: 'noteContentsModal', saga }),
);

export default enhance(NotesDialog);