import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

class NoteContent extends EntityRecord({
  note: {
    value: null,
    isEntity: true,
    handleCopy: true,
    serializeOutputField: 'noteId',
  },
  contentType: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  contentValue: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  contentDefaultValue: {
    value: null,
    handleCompare: true,
    handleSerialize: true
  },
}) {}

export default NoteContent;
