import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';
import selectEntities from '../../modules/entities/selectors';
import CurrentUserSchema from './schema';

export const selectNormalizedCurrentUser = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CURRENT_USER.state).first()
);

export const selectIsPendingForcedPasswordReset = () => createSelector(
  selectNormalizedCurrentUser(),
  currentUser => currentUser && currentUser.passwordResetRequired
);

export const selectCurrentUser = () => createSelector(
  selectEntities(),
  entities => {
    const currentUserState = entities.get(EK.CURRENT_USER.state);
    if (!currentUserState) {
      return null;
    }

    const denormalizedUser = denormalize(currentUserState, [CurrentUserSchema], entities);
    return denormalizedUser && typeof denormalizedUser.first === 'function' ? denormalizedUser.first() : null;
  }
);

export const selectCurrentOrganization = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.organization
);

export const selectCurrentOrganizationId = () => createSelector(
  selectCurrentOrganization(),
  organization => organization.id
);

export const selectCurrentUserAllowedRoleAssignments = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.allowedRoleAssignments
);
