import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { List } from 'immutable';

import Flex from '../../common/Flex';
import ComplexCellRenderer from '../ComplexCellRenderer';
import Text from '../../common/Text';
import Button from '../../common/Button';

import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import ListCreatorEditor from '../ListCreatorEditor';

import { BlankNoteContent } from './index';
import { selectParametersOptions } from '../../../entities/Standardize/Parameters/selectors';
import { useSelector } from 'react-redux';

export const NoteContentsListContainer = styled.div`
  margin-left: 1%;
  float: right;

  font-size: 1rem;

  border: ${({ theme }) => theme.borders[1]};
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;

  min-height: 11.5rem;
  max-height: 30rem;

  min-width: 10rem;
  width: 29%;

  overflow-y: hidden;
`;

export const NoteContentsListHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  border-bottom: ${({ theme }) => theme.borders[1]};
`;

const NoContentsBase = styled(Text)`
  border: ${({ theme }) => theme.borders[1]};
  padding: 1rem;
`;

const NoContents = () => <NoContentsBase>No Note Contents</NoContentsBase>;

export const NoteContentEditor = ({
  currentNoteContent,
  e,
  onEditNoteContent,
  onFocusNoteContent,
  onBlurNoteContent,
  editedRows,
  noteContents,
  onDropdownComplete
}) => {

  const parameters = useSelector(selectParametersOptions());
  const [originalNoteContent, setOriginalNoteContent] = useState(() => {
    if (e && e.rowData?.id) {
      return e.rowData;
    }

    return currentNoteContent;
  });

  const value = useMemo(() => {

    if (currentNoteContent.id == originalNoteContent.id) {
      return currentNoteContent.contentDefaultValue;
    }

    const editedRow = editedRows.find(row => row.id == originalNoteContent.id);
    if (editedRow) {
      return editedRow.contentDefaultValue;
    }

    return originalNoteContent.contentDefaultValue;
  }, [currentNoteContent, originalNoteContent, editedRows]);

  const getContentValue = () => {
    // Return empty array for list if no content, otherwise empty string
    if (!currentNoteContent) return [];

    if (currentNoteContent.contentType == 'list') {
      let listItems = [];
      const rawValue = currentNoteContent.contentValue;

      if (typeof rawValue === 'string' && rawValue.trim() !== '') {
        try {
          // Attempt to parse the JSON string into an array
          const parsedArray = JSON.parse(rawValue);
          if (Array.isArray(parsedArray)) {
            // Map the parsed string array to the object format needed
            listItems = parsedArray.map(itemValue => ({
              id: String(itemValue), // Ensure ID is string
              value: String(itemValue),
              primary: String(itemValue),
              isSelected: false // Default selection state
            }));
          }

        } catch (error) {
          // Fallback: Maybe try splitting by comma as a last resort? Or just return empty?
          // For now, let's return empty to avoid malformed data.
          listItems = [];
        }

      } else if (Array.isArray(rawValue)) {
        // If it's somehow already an array (e.g., during initial creation before save)
        listItems = rawValue.map(v => ({
          id: String(v?.id ?? v?.value ?? v), // Handle object or string items
          value: String(v?.value ?? v),
          primary: String(v?.primary ?? v?.value ?? v),
          isSelected: v?.isSelected ?? false
        }));
      }

      // Mark the default value as selected
      const defaultValue = currentNoteContent.contentDefaultValue;
      if (defaultValue !== null && defaultValue !== undefined) {
        listItems = listItems.map(item => ({
          ...item,
          isSelected: String(item.value) === String(defaultValue) // Compare as strings
        }));
      }
      return listItems;
    }

    // For non-list types, return the value directly
    return currentNoteContent.contentValue;
  };

  useEffect(() => {
    setOriginalNoteContent((onc) => {
      if (onc) {
        return onc;
      }

      if (e && e.rowData?.id) {
        return e.rowData;
      }
    });
  }, [currentNoteContent, e, noteContents]);

  switch (originalNoteContent?.contentType) {
  case 'date':
    return <input
      type='date'
      className='note-content-input'
      id={currentNoteContent.id}
      value={value}
      onChange={(e) => onBlurNoteContent(
        currentNoteContent.id,
        {
          id: currentNoteContent.id,
          contentValue: e.target.value,
          contentDefaultValue: e.target.value,
          contentType: 'date'
        }
      )}
      onFocus={() => onFocusNoteContent(currentNoteContent)}
    />;

  case 'list':
    return <ListCreatorEditor
      value={List(getContentValue())}
      defaultValue={value}
      onChange={(newValue) => onEditNoteContent(newValue.toArray(), 'contentValue')}
      onDefaultValueChange={(newValue) => onEditNoteContent(newValue, 'contentDefaultValue')}
      minListSize={1}
      maxListSize={100}
      className='note-content-input'
    />;

  case 'text':
    return <InputText
      className='note-content-input'
      id={currentNoteContent.id}
      value={value}
      onChange={(e) => onEditNoteContent(e.target.value, 'contentDefaultValue')}
      onBlur={() => onBlurNoteContent(currentNoteContent.id)}
    />;

  case 'number':
    return <InputNumber
      className='note-content-input'
      id={currentNoteContent.id}
      value={value}
      onChange={(e) => onEditNoteContent(e.value, 'contentDefaultValue')}
      onBlur={() => onBlurNoteContent(currentNoteContent.id)}
    />;

  case 'parameter':
    return <Dropdown
      value={value}
      options={parameters}
      filter={true}
      optionLabel='name'
      optionValue='id'
      onChange={(e) => onDropdownComplete.current(e.value, 'contentDefaultValue', currentNoteContent)}
      style={{ width: '100%' }}
    />;

  default:
    return <Text>No Editor Found</Text>;
  }
};