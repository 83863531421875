import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { List, set } from 'immutable';
import styled, { css } from 'styled-components';

import './BaseListCreator.css';

import times from 'lodash/times';
import trim from 'lodash/trim';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import Text from '../../common/Text';

import BaseInput from '../BaseInput';
import BaseAppend from '../BaseAppend';
import renderCreatedValueForList from '../BaseOption/utils/renderCreatedValueForList';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import { pluralize } from '../../common/Header';

const Selectable = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};
  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[2]};

  transition: transform ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.out};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $listValue }) =>
    !$listValue &&
    css`
      transform: scale(0.9);
      background-color: ${({ theme }) => theme.colors.gray[0]};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.gray[1]};
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary[4]};
      background-color: ${({ theme }) => theme.colors.primary[0]};
    `}
`;

const SECTION_LIMIT = 25;
const COLUMN_LIMIT = 5;

const calculateRequiredSectionCount = (listValuesSize) => {
  const requiredSections = Math.ceil(listValuesSize / SECTION_LIMIT) || 1;
  return requiredSections > SECTION_LIMIT ? SECTION_LIMIT : requiredSections;
};

const calculateRequiredColumnCount = (listValuesSize) => {
  if (listValuesSize < 25) {
    const requiredColumns = Math.ceil(listValuesSize / COLUMN_LIMIT) || 1;
    return requiredColumns > COLUMN_LIMIT ? COLUMN_LIMIT : requiredColumns;
  } else {
    return COLUMN_LIMIT;
  }
};

const noop = () => null;

const CreatedListValue = ({
  listValue,
  onDeleteListValue,
  onToggleDefaultListValue,
  itemHeight,
}) => {
  const onClick = useCallback(() => onToggleDefaultListValue(listValue.value), [
    listValue,
    onToggleDefaultListValue,
  ]);
  const onDelete = useCallback(() => onDeleteListValue(listValue.value), [
    listValue,
    onDeleteListValue,
  ]);

  return (
    <Selectable
      $listValue={listValue}
      onClick={(listValue && onClick) || undefined}
      $isSelected={listValue && listValue.isSelected}
      isSelected={listValue && listValue.isSelected}
      height={itemHeight}
      flexDirection='column'
      justifyContent='center'
    >
      {(listValue &&
        renderCreatedValueForList({
          item: listValue,
          isSelected: listValue.isSelected,
          onDelete,
        })) ||
        undefined}
    </Selectable>
  );
};

const CreateListInstructions = styled(Text)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const CreateListCount = styled(Text)`
  flex-grow: 0;
  flex-shrink: 0;
`;

const CreatedListSections = styled(Flex)`
  max-height: calc(
    ${({ maxInnerHeight }) => maxInnerHeight}px + (2 * ${({ theme }) => theme.space[2]})
  );
  overflow-y: auto;
`;

const CreatedListColumn = styled(Flex)`
  flex-direction: column;

  ${({ isLastColumn }) =>
    (isLastColumn &&
      css`
        width: calc(
          ${({ minBaseWidth }) =>
        !isNaN(minBaseWidth - parseFloat(minBaseWidth))
          ? `${minBaseWidth}px`
          : minBaseWidth} - ${({ theme }) => theme.space[2]}
        );
      `) ||
    css`
      width: ${({ minBaseWidth }) =>
    !isNaN(minBaseWidth - parseFloat(minBaseWidth))
      ? `${minBaseWidth}px`
      : minBaseWidth};
      margin-right: ${({ theme }) => theme.space[2]};
    `}
`;

const CreatedListValues = ({
  listValues,
  onDeleteListValue,
  onToggleDefaultListValue,
  baseWidth,
  itemHeight,
  onOuterClick = noop,
  minListSize,
  maxListSize,
}) => {
  const containerRef = useRef();
  const scrollableRef = useRef();
  const sectionCount = useMemo(
    () => calculateRequiredSectionCount(listValues.size),
    [listValues.size]
  );
  const columnCount = useMemo(
    () => calculateRequiredColumnCount(listValues.size),
    [listValues.size]
  );
  const minBaseWidth = useMemo(
    () =>
      (baseWidth > 250 &&
        baseWidth / columnCount > 250 &&
        baseWidth / columnCount) ||
      250,
    [baseWidth, columnCount]
  );
  const isListSizeWarning =
    listValues.size >= minListSize && listValues.size < maxListSize;

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        behavior: 'smooth',
        top: scrollableRef.current.scrollHeight,
      });
    }
  }, [listValues.size]);

  useOnClickOutside(containerRef, onOuterClick);

  return (
    <Box
      ref={containerRef}
      className='BaseListContainer'
      $width={minBaseWidth * columnCount}
      $bg='gray.0'
      $border={1}
      $borderRadius={2}
      $boxShadow={2}
    >
      <Flex
        flexDirection='row'
        alignItems='center'
        p={2}
        borderBottom={1}
      >
        <CreateListInstructions color='gray.6' fontSize={0}>
          Press <strong>Enter</strong> to add options
        </CreateListInstructions>
        <CreateListCount color='gray.6' fontSize={0}>
          <Text
            as='span'
            color={isListSizeWarning ? 'gray.6' : 'error.4'}
            fontWeight={isListSizeWarning ? '500' : '600'}
          >
            {listValues.size}
          </Text>{' '}
          /{' '}
          <Text
            as='span'
            color={listValues.size < maxListSize ? 'gray.6' : 'error.4'}
            fontWeight={listValues.size < maxListSize ? '500' : '600'}
          >
            {maxListSize}
          </Text>
        </CreateListCount>
      </Flex>
      <CreatedListSections
        ref={scrollableRef}
        flexDirection='column'
        p={2}
        maxInnerHeight={itemHeight * COLUMN_LIMIT}
      >
        {times(sectionCount, (sidx) => (
          <Flex
            flexDirection='row'
            style={{
              paddingBottom: sectionCount !== sidx + 1 ? theme.space[2] : undefined,
            }}
          >
            {times(columnCount, (idx) => (
              <CreatedListColumn
                key={sidx * SECTION_LIMIT + idx}
                minBaseWidth={minBaseWidth}
                height={itemHeight * COLUMN_LIMIT}
                isLastColumn={columnCount === idx + 1}
              >
                {times(COLUMN_LIMIT, (ridx) => {
                  const itemIndex =
                    sidx * SECTION_LIMIT + idx * COLUMN_LIMIT + ridx;
                  return (
                    <CreatedListValue
                      key={itemIndex}
                      listValue={listValues.get(itemIndex)}
                      onDeleteListValue={onDeleteListValue}
                      onToggleDefaultListValue={onToggleDefaultListValue}
                      itemHeight={itemHeight}
                    />
                  );
                })}
              </CreatedListColumn>
            ))}
          </Flex>
        ))}
      </CreatedListSections>
    </Box>
  );
};

export const ForwardedBaseListCreator = ({
  forwardRef=null,
  type,
  value,
  onChange,
  onDefaultValueChange,
  width = '100%',
  itemHeight,
  tabIndex,
  minListSize,
  maxListSize,
  allow,
  onOuterClick,
  defaultValue,
  onFocus,
  onBlur
}) => {
  const inputRef = useRef(forwardRef);

  const [isOpen, setIsOpen] = useState(false);

  const [values, setValues] = useState(value || List());

  const [currentValue, setCurrentValue] = useState('');

  const [listDefaultValue, setDefaultValue] = useState(defaultValue);

  const [placeholder, setPlaceholder] = useState(
    values.size > 0
      ? `${listDefaultValue} (${values.size} ${pluralize('value', values.size)} total) Add List Values Here`
      : 'Add List Values Here'
  );

  const onListValuesChange = useCallback(
    ({ values }) => {
      onChange && onChange(values);
      inputRef.current && inputRef.current.focus();
    },
    [onChange, inputRef.current]
  );

  const handleAppendClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, [isOpen]);

  const onDeleteListValue = useCallback(
    (removeValue) => {
      // 1. Calculate the new list *before* setting state
      const newValues = values.filter(({ value }) => value !== removeValue);

      // 2. Update state with the calculated list
      setValues(newValues);

      // 3. Call onChange with the same calculated list
      onChange && onChange(newValues);
    },
    [values, onChange] // Need values here now
  );

  const handleToggleDefaultListValue = (value, isSelected) => {
    const newdefval = isSelected ? undefined : value;
    onDefaultValueChange && onDefaultValueChange(newdefval);
    setDefaultValue(newdefval);
    return !isSelected;
  };

  const onToggleDefaultListValue = useCallback(
    (toggleValue) => {
      setIsOpen(true);
      let newValues;
      setValues((currentValues) => {
        let newDefaultValue = undefined;
        newValues = currentValues.map(({ value, isSelected, ...vrest }) => {
          const shouldBeSelected = String(value) === String(toggleValue) ? !isSelected : false;
          if (shouldBeSelected) {
            newDefaultValue = value; // Capture the new default value
          }
          return {
            ...vrest,
            value,
            isSelected: shouldBeSelected
          };
        });
        // Update the internal default value state AFTER mapping
        setDefaultValue(newDefaultValue);
        // Also call the prop handler for default value change
        onDefaultValueChange && onDefaultValueChange(newDefaultValue);
        return newValues;
      });
      // Call onChange AFTER state update with the new list (containing updated isSelected flags)
      // onChange && onChange(newValues); // This might be too noisy, parent likely only cares about default change here?
      // Let's only call onChange when items are added/removed.
    },
    [onDefaultValueChange] // Removed values dependency
  );

  const onListValueCreatorFocus = () => {
    setIsOpen(true);
  };

  const onListValueCreatorUnfocus = () => {
    setIsOpen(false);
    onOuterClick && onOuterClick();
  };

  const onCurrentValueChange = useCallback(
    (e) => {
      e.persist();
      setCurrentValue(e.target.value);
    },
    [currentValue]
  );

  const onListValueSubmit = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        if (trim(currentValue).length > 0) {
          const foundIndex = values.findIndex(({ value }) => value === currentValue);
          const newValueToAdd = currentValue;
          setCurrentValue('');

          if (foundIndex === -1 && values.size < maxListSize) {
            let isFirstItem = values.size === 0;

            // 1. Calculate the new list *before* setting state
            const newValues = values.push({
              id: newValueToAdd,
              value: newValueToAdd,
              primary: newValueToAdd,
              isSelected: isFirstItem || undefined,
            });

            // 2. Update state with the calculated list
            setValues(newValues);

            // 3. Call onChange with the same calculated list
            onChange && onChange(newValues);

            if (isFirstItem) {
              onDefaultValueChange && onDefaultValueChange(newValueToAdd);
              setDefaultValue(newValueToAdd);
            }
          }
        }
      }
    },
    [values, currentValue, maxListSize, onChange, onDefaultValueChange]
  );

  // Reinstated useEffect to handle initial default value selection
  // This is needed if the parent doesn't pre-process the `value` prop
  // to include the `isSelected` flag correctly.
  useEffect(() => {
    // Only run on initial mount if defaultValue is provided
    if (defaultValue !== null && defaultValue !== undefined) {
      setValues((currentValues) => {
        // Check if the default value actually exists in the list
        const defaultValueExists = currentValues.some(item => String(item.value) === String(defaultValue));
        if (!defaultValueExists) {
          return currentValues; // Don't modify if default isn't in the list
        }

        // Create the new list with the correct item selected
        return currentValues.map(item => ({
          ...item,
          // Set isSelected to true if item value matches defaultValue, otherwise false (or undefined)
          isSelected: String(item.value) === String(defaultValue)
        }));
      });
    }
  }, []);

  // This useEffect now ONLY updates the placeholder
  useEffect(() => {
    setPlaceholder(values.size > 0
      ? `${listDefaultValue || 'Select Default'} (${values.size} ${pluralize('value', values.size)} total)`
      : 'Add List Values Here');
    // REMOVED: onChange && onChange(values);
  }, [values, listDefaultValue]); // Removed onChange from dependencies

  return (
    <div>
      <div
        className='BaseInputContainer'
        onFocus={onListValueCreatorFocus}
        onBlur={onListValueCreatorUnfocus}
      >
        <input
          ref={inputRef}
          value={currentValue || ''}
          onChange={onCurrentValueChange}
          type={type}
          tabIndex={tabIndex}
          allow={allow}
          onKeyDown={onListValueSubmit}
          placeholder={placeholder}
          className='BaseInput'
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <BaseAppend onClick={handleAppendClick} style={{ width: '10%', cursor: 'pointer' }}>
          <Icon name='chevron-down' iconSize='1.75rem' />
        </BaseAppend>
      </div>
      <div className='ListValuesContainer' style={{ maxHeight: isOpen ? '500px' : '0px', width: '100%' }} >
        <CreatedListValues
          listValues={values}
          onDeleteListValue={onDeleteListValue}
          onToggleDefaultListValue={onToggleDefaultListValue}
          baseWidth={width}
          itemHeight={itemHeight || 56}
          onOuterClick={onOuterClick}
          minListSize={minListSize}
          maxListSize={maxListSize}
        />
      </div>
    </div>
  );
};

const BaseListCreator = React.forwardRef((props, ref) => (
  <ForwardedBaseListCreator forwardRef={ref} {...props} />
));

export default BaseListCreator;