import { Map } from 'immutable';

import resetOnLogout from '../../../utils/reducers/resetOnLogout';

import { NOTE_CONTENT_CREATED, NOTE_CONTENT_CREATED_ERROR, NOTE_CONTENT_ADD_SUCCESS, NOTE_CONTENT_DELETED } from './constants';
import { fetchAllNoteContentsTypes, saveNoteContentsTypes } from '../../../entities/Standardize/NoteContents/constants';
import { saveNotes } from '../../../entities/Standardize/Notes/constants';

const initialModalState = Map({ noteContentCreated: false, newNoteContent: null, noteContentsLoading: true, noteContentDeleted: null });

function noteContentReducer(state = initialModalState, { type, payload }) {
  if (type === NOTE_CONTENT_CREATED) {
    return state.set('noteContentCreated', true).set('newNoteContent', payload);
  } else if (type === NOTE_CONTENT_CREATED_ERROR) {
    return state.set('noteContentCreated', false);
  } else if (type === NOTE_CONTENT_ADD_SUCCESS) {
    return state.set('noteContentCreated', false).delete('newNoteContent');
  } else if (type === fetchAllNoteContentsTypes.SUCCESS || type === saveNoteContentsTypes.REQUEST) {
    return state.set('noteContentsLoading', false);
  } else if (type === fetchAllNoteContentsTypes.REQUEST || type === saveNoteContentsTypes.REQUEST) {
    return state.set('noteContentsLoading', true);
  } else if (type === NOTE_CONTENT_DELETED) {
    return state.set('noteContentDeleted', payload);
  } else if (type === saveNotes.REQUEST) {
    return state.set('noteContentsLoading', true);
  } else if (type === saveNotes.SUCCESS || type === saveNotes.FAILURE) {
    return state.set('noteContentsLoading', false);
  } else {
    return state;
  }
}

export default resetOnLogout(noteContentReducer);