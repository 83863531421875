import React, { Component, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const EditableDiv = styled.div`
  font-size: 1rem;

  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;

  min-height: 2.5rem;

  min-width: 10rem;
  width: 70%;

  line-height: 2;

  &.note-body-editor {
    /* No additional styles needed, just for the class selector */
  }
`;

const NoteContentTag = ({ noteContent, index, parameters, editedRows }) => {
  // Check if this note content has been edited - more robust check
  const editedContent = editedRows?.find(row => row && row.id === noteContent.id);

  // Use nullish coalescing (??) to handle 0 correctly. Prioritize edited, then original.
  const currentValue = editedContent?.contentDefaultValue ?? noteContent.contentDefaultValue;

  let dispVal = currentValue;
  if (noteContent.contentType === 'parameter') {
    dispVal = parameters.find(p => p.id === currentValue)?.name;
  }

  // Use explicit check and String() conversion for the final display value
  let finalDisplayValue;
  if (dispVal === null || dispVal === undefined) {
    finalDisplayValue = 'no default value';
  } else {
    finalDisplayValue = String(dispVal); // Convert 0 to "0"
  }

  // Always display the original 1-based index to user, regardless of position in body
  const displayIndex = noteContent.index !== undefined ? `${noteContent.index + 1}: ` : '';

  return (
    <span
      contentEditable={false}
      className={'note-content-tag ncid' + noteContent.id}
      id={noteContent.id}
      data-placeholder={`\${${noteContent.id}}`}
      data-content-index={noteContent.index !== undefined ? noteContent.index : -1}
    >
      {displayIndex}{finalDisplayValue}
    </span>
  );
};

export default function ({ elements, parameters, editedRows, lastSelectionRangeRef }) {

  // Handle paste events to strip formatting and HTML entities
  const handlePaste = (e) => {
    // Prevent the default paste which might include formatted text
    e.preventDefault();

    // Get plain text from clipboard
    const text = e.clipboardData.getData('text/plain');

    // Insert it at cursor position
    document.execCommand('insertText', false, text);
  };

  // Update spans when noteContents changes
  useEffect(() => {
    if (elements && elements.length > 0) {

      // First, collect all unique note contents to get their proper indices
      const uniqueNoteContents = new Map();

      elements.forEach((line) => {
        line.forEach((element) => {
          if (typeof element === 'object' && element && element.id) {
            if (!uniqueNoteContents.has(element.id)) {
              uniqueNoteContents.set(element.id, {
                ...element,
                displayIndex: element.index !== undefined ? `${element.index + 1}: ` : ''
              });
            }
          }
        });
      });

      // Now update all spans for each note content
      uniqueNoteContents.forEach((noteContent) => {
        // Find all instances of this note content in the DOM
        const spans = document.querySelectorAll(`span.ncid${noteContent.id}`);
        if (spans.length > 0) {
          spans.forEach(span => {
            const displayValue = noteContent.contentDefaultValue || noteContent.contentValue || 'no default value';
            const displayIndex = noteContent.index !== undefined ? `${noteContent.index + 1}: ` : '';

            span.innerHTML = `${displayIndex}${displayValue}`;
            span.setAttribute('data-content-index', noteContent.index !== undefined ? noteContent.index : -1);
          });
        }
      });
    }
  }, [elements]);

  // Function to save the current selection range
  const saveSelection = useCallback(() => {
    if (typeof document !== 'undefined') { // Ensure document is available
      const selection = document.getSelection();
      const editor = document.getElementById('editable-div-container');
      if (selection && selection.rangeCount > 0 && editor && editor.contains(selection.anchorNode)) {
        const range = selection.getRangeAt(0);

        if (lastSelectionRangeRef) {
          lastSelectionRangeRef.current = range;
        }
      }
    }
  }, [lastSelectionRangeRef]); // Dependency array includes the ref prop

  return (
    <EditableDiv
      role='textbox'
      id='editable-div-container'
      className="note-body-editor"
      contentEditable
      suppressContentEditableWarning={true}
      onPaste={handlePaste}
      // Update selection on common interaction events
      onMouseUp={saveSelection}
      onKeyUp={saveSelection}
      // onFocus={saveSelection} // REMOVED: Might be causing issues
      // onSelect={saveSelection} // Consider adding if needed, might be redundant
    >
      {elements.map((line, i) => {
        return (
          <div key={i}>
            {line.length === 1 && !line[0] ? (
              <br />
            ) : (
              line.map((element, i) => {
                if (typeof element === 'string' || !element.id) {
                  return element;
                }

                return (
                  <NoteContentTag
                    key={element.id}
                    noteContent={element}
                    index={element.index}
                    parameters={parameters}
                    editedRows={editedRows}
                  />
                );
              })
            )}
          </div>
        );
      })}
    </EditableDiv>
  );
}