import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { PipingModuleSpecsEditPath } from '../../../paths';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processFetchAllSpecs,
  processArchiveSpecs,
  processUnarchiveSpecs,
  processCreateSpec,
  processCopySpec,
  processEditSpec,
  processDeleteSpec
} from '../../../entities/Piping/Specs/actions';

import { processFetchAllAppearances } from '../../../entities/Synchronize/Appearances/actions.js';
import { processFetchAllInsulationSpecs } from '../../../entities/Piping/InsulationSpecs/actions.js';
import { selectNormalizedAppearances } from '../../../entities/Synchronize/Appearances/selectors';
import { selectNormalizedInsulationSpecs } from '../../../entities/Piping/InsulationSpecs/selectors';

import { VALIDATION_FIELDS } from '../../../entities/Piping/Specs/model';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';

import { selectCurrentFilteredDraftSpecs } from './selectors';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import Header from './Header';

import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import TextCell from '../../../components/grid/TextCell';
import SpecsDialog from '../../../containers/dialog/templates/Piping/SpecsDialog';

const BLANK_ROW = {
  id: '',
  specName: '',
  mnemonic: '',
  layerName: '',
  appearance: '',
  defaultInsulationSpec: '',
};

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedAppearances(),
  selectNormalizedInsulationSpecs(),
  selectCurrentFilteredDraftSpecs(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    appearances,
    insulationSpecs,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size == 0));
    return {
      ...rest,
      isLoading,
      appearances: appearances.toList().toArray() || [],
      insulationSpecs: insulationSpecs && insulationSpecs.toList().toArray() || [],
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function SpecsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_ROW);

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);         // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);


  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_ROW);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_ROW);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of the delete button in the edit dialog, always use the delete modal
    dispatch(processDeleteSpec(currentEditingRow.id));
    setCurrentEditingRow(BLANK_ROW);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    // closes the other dialogs and opens the delete Dialog
    setIsDialogOpen(false);
    setIsDeleteDialogOpen(true);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };

  const saveAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      specName: currentEditingRow.specName,
      mnemonic: currentEditingRow?.mnemonic,
      layerName: currentEditingRow?.layerName,
      appearanceId: currentEditingRow.appearance.id,
      defaultInsulationSpecId: currentEditingRow?.defaultInsulationSpec?.id
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateSpec(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopySpec(editedRow));
    } else {
      dispatch(processEditSpec(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const goToPipingSpecsEditPath = (insulationId) => {
    const link = PipingModuleSpecsEditPath.generate({ id: insulationId });
    dispatch(push(link));
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      specName: rowdata.specName,
      mnemonic: rowdata.mnemonic,
      layerName: rowdata.layerName,
      appearance: rowdata.appearance,
      defaultInsulationSpec: rowdata.defaultInsulationSpec,
      canSafelyDelete: rowdata.specProjectIds?.length <= 0,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    setCurrentEditingRow(rowdata);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveSpecs([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveSpecs([rowdata.id]));
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_ROW);
  };

  useEffect(() => {
    dispatch(processFetchAllSpecs());
    dispatch(processFetchAllAppearances());
    dispatch(processFetchAllInsulationSpecs());
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>

      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column
            field='name'
            header='Name'
            body={(rowData) => nameBody(rowData, goToPipingSpecsEditPath)}
            style={{ width: '20%' }}
            sortable
          />
          <Column
            field='mnemonic'
            header='Mnemonic'
            body={(rowData =>
              TextCell({ value: rowData.mnemonic, placeholder: 'No Mnemonic' })
            )}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='instancesCount'
            header='Pipe Instances'
            bodyStyle={{ fontSize: '14px' }}
            body={(rowData =>
              TextCell({ value: rowData.instancesCount.split(',')[0], placeholder: '0' })
            )}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='instancesCount'
            header='Fitting Instances'
            bodyStyle={{ fontSize: '14px' }}
            body={(rowData =>
              TextCell({ value: rowData.instancesCount.split(',')[1], placeholder: '0' })
            )}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            header=''
            bodyStyle={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => goToPipingSpecsEditPath(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />
            }
          ></Column>
        </DataTable>
      </DataTableContainer>
      <SpecsDialog
        currentEditingRow={currentEditingRow}
        handleEditedRowChange={handleEditedRowChange}
        isDialogOpen={isDialogOpen}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        editable={reduxProps.editable}
      />
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Piping Spec '${currentEditingRow.specName}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction, currentEditingRow.canSafelyDelete)} closable={false} >
        <div>
          {currentEditingRow.canSafelyDelete &&
            <>
              <Text>Are you sure you want to delete {currentEditingRow.specName}?</Text>
              <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
              <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
            </> ||
            <Text><strong>Please remove this Spec from all Projects before deleting.</strong></Text>
          }
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(SpecsGrid);
