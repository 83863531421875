import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectParametersOptions } from '../../../entities/Standardize/Parameters/selectors';

import Box from '../../common/Box';
import Button from '../../common/Button';
import Flex from '../../common/Flex';
import NoteContentList, { NoteContentsListHeader, NoteContentEditor, NoteContentsListContainer } from './NoteContentList';
import NoteBodyEditor from './NoteBodyEditor';
import EntityRowTools from '../../common/EntityTools/RowStartTools';
import Icon from '../../common/Icon';
import Popup from 'reactjs-popup';
import TextCell from '../TextCell';

import { rowStyles } from '../../primeGrid/RowStyles';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import './styles.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

export const BlankNoteContent = {
  id: '',
  contentType: '',
  contentDefaultValue: '',
  contentValue: ''
};

/**
 * Replaces all span tags in a parent element with their corresponding note content ID values
 * in the format ${noteContentId}. Used to convert the visual editor representation to the
 * stored note body format.
 *
 * @param {HTMLElement} parent - The parent element containing span tags to replace
 * @example
 * // Before: <span id="123" class="note-content-tag">Some content</span>
 * replaceSpanTags(parentElement);
 * // After: ${123}
 */
export const replaceSpanTags = (parent) => {
  parent.querySelectorAll('span').forEach(span => {
    const ncId = span.id;
    const ncVal = `\${${ncId}}`;
    span.outerHTML = ncVal;
  });
};

// Helper function to convert spans to ID references
export const prepareNoteBodyForSave = (htmlContent) => {
  // Create a temporary div to manipulate the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  // Find all spans with note-content-tag class
  const spans = tempDiv.querySelectorAll('span.note-content-tag');

  // Replace each span with its ID reference
  spans.forEach(span => {
    const id = span.id;
    if (id) {
      span.outerHTML = `\${${id}}`;
    }
  });

  const html = tempDiv.innerHTML;

  // Decode common HTML entities
  const entities = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': '\'',
    '&apos;': '\'',
    '&#x2F;': '/'
  };

  let result = html;
  for (const [entity, char] of Object.entries(entities)) {
    result = result.replaceAll(entity, char);
  }

  return result;
};

export default function MultiValueNoteBodyEditor({
  note,
  isDialogOpen,
  createNoteContent,
  deleteNoteContent,
  resetNoteContentCreated,
  noteContentCreated,
  newNoteContent,
  noteContentDeleted,
  updateNoteContents,
  ref
}) {

  // Filter out invalid note contents when initializing
  const validNoteContents = note && note.noteContents ?
    note.noteContents.filter(nc => nc && nc.id) : [];

  const [noteContents, setNoteContents] = useState(validNoteContents);
  const [noteBody, setNoteBody] = useState(note && note.noteBody || '');
  const [currentNoteContent, setCurrentNoteContent] = useState({ id: '', contentValue: '', contentDefaultValue: '', contentType: '' });
  const [shouldShowAddButtons, setShouldShowAddButtons] = useState(false);

  const [elements, setElements] = useState([]);
  const [usedParameters, setUsedParameters] = useState([]);
  const [grid, setGrid] = useState();

  // Ref to store the last known valid selection range within the editor
  const lastSelectionRangeRef = useRef(null);

  // Get parameters from the selector
  const parameters = useSelector(selectParametersOptions());

  const CERef = useRef();
  const tableRef = useRef();

  // Function to convert a database note content to a valid one for the grid
  const convertToType = (noteContent) => {
    if (!noteContent) return null;

    return {
      id: noteContent.id || '',
      // Use nullish coalescing to preserve 0 and false, fallback to empty string
      contentValue: noteContent.contentValue ?? noteContent.contentDefaultValue ?? '',
      contentDefaultValue: noteContent.contentDefaultValue ?? '',
      contentType: noteContent.contentType || 'text',
      // Ensure index is a number, default to 0 if not present or invalid
      index: typeof noteContent.index === 'number' && !isNaN(noteContent.index) ? noteContent.index : 0
    };
  };

  // adds the span tag to the note body in the desired location
  const addNoteContentToBody = (noteContent) => {

    const editor = document.getElementById('editable-div-container');
    if (!editor) {
      return;
    }

    // IMPORTANT: Focus the editor first (might still be needed for other interactions)
    editor.focus();

    // Get the stored range from the ref
    let currentRange = lastSelectionRangeRef.current;
    const selection = document.getSelection(); // Still useful for placing cursor *after* insertion

    // Fallback logic: If no stored range, place cursor at the end
    if (!currentRange) {
      const range = document.createRange();
      if (editor.lastChild) {
        range.selectNodeContents(editor.lastChild);
        range.collapse(false); // collapse to end
      } else {
        range.setStart(editor, 0);
        range.setEnd(editor, 0);
      }

      // We need the selection object to add the range
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        currentRange = range; // Use this newly created range for insertion
      } else {
        return; // Cannot proceed without a selection object
      }
    }

    const displayIndex = noteContent.index !== undefined ? `${noteContent.index + 1}: ` : '';
    const displayValue = noteContent.contentDefaultValue || 'no default value';

    // Now insert the note content using the determined range (stored or fallback)
    const newNCSpan = document.createElement('span');
    newNCSpan.id = noteContent.id;
    newNCSpan.className = 'note-content-tag ncid' + noteContent.id;
    newNCSpan.innerHTML = `${displayIndex}${displayValue}`;
    newNCSpan.setAttribute('data-content-index', noteContent.index !== undefined ? noteContent.index : -1);
    newNCSpan.contentEditable = false;

    try {
      // Insert the span at the determined cursor position
      currentRange.insertNode(newNCSpan);
    } catch (error) {
      // Potentially attempt fallback insertion at end if insertion failed?
      // For now, just log the error.
      return;
    }

    // Add a space after the span if needed
    const nextNode = newNCSpan.nextSibling;
    if (!nextNode || nextNode.nodeType !== Node.TEXT_NODE || !nextNode.textContent.startsWith(' ')) {
      const spaceNode = document.createTextNode(' ');

      // Check if parentNode exists before inserting
      if (newNCSpan.parentNode) {
        newNCSpan.parentNode.insertBefore(spaceNode, nextNode);
      }
    }

    // Move cursor after the inserted span
    // We need a range object to manipulate the selection
    const cursorMoveRange = document.createRange();
    cursorMoveRange.setStartAfter(newNCSpan);
    cursorMoveRange.collapse(true);
    if(selection) {
      selection.removeAllRanges();
      selection.addRange(cursorMoveRange);
    }

    // Update elements and state
    handleNoteChange();

    const noteBodyEditor = document.querySelector('.note-body-editor');
    if (noteBodyEditor) {
      const processedContent = prepareNoteBodyForSave(noteBodyEditor.innerHTML);
      setNoteBody(noteBodyEditor.innerHTML);
    }
  };

  // onFocusNoteContent and onBlurNoteContent manage styling for NoteBodyEditor through state
  const onFocusNoteContent = (noteContent) => {
    // Find all instances of this note content in the body
    const spans = document.querySelectorAll(`span.ncid${noteContent.id}`);
    if (spans.length > 0) {
      spans.forEach(span => {
        span.classList.add('current-note-content');
      });
    }
  };

  const onBlurNoteContent = (ncid) => {
    // Find all instances of this note content in the body
    const spans = document.querySelectorAll(`span.ncid${ncid}`);
    if (spans.length > 0) {
      spans.forEach(span => {
        span.classList.remove('current-note-content');
      });
    }
  };

  const hoverNoteContent = (ncid) => {
    // Find all instances of this note content in the body
    const spans = document.querySelectorAll(`span.ncid${ncid}`);
    if (spans.length > 0) {
      spans.forEach(span => {
        span.classList.add('hovered-note-content');
      });
    }
  };

  const unhoverNoteContent = (ncid) => {
    // Find all instances of this note content in the body
    const spans = document.querySelectorAll(`span.ncid${ncid}`);
    if (spans.length > 0) {
      spans.forEach(span => {
        span.classList.remove('hovered-note-content');
      });
    }
  };

  const [
    editedRows,
    resetEditedRows,
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditComplete,
    handleRowAction,
    onDropdownComplete
  ] = useOnCellEditComplete(
    note.noteContents,
    convertToType,
    true,
    true
  );

  const contentStyle = { width: 'fit-content' };

  // We'll implement our own handleRowAction instead of using the one from useOnCellEditComplete
  const customHandleRowAction = (item, iconName) => {
    if (!item || !item.id) {
      return;
    }

    // If we're deleting an item, call the deleteNoteContent function
    if (iconName === 'delete') {

      if (typeof deleteNoteContent === 'function') {
        // Call the provided deleteNoteContent function
        deleteNoteContent(item.id);

        // Also manually update our local state as a fallback
        setNoteContents(prev => prev.filter(content => content && content.id !== item.id));

      } else {
        // Fallback to handleRowAction for delete
        handleRowAction(item, iconName);
      }
      return;
    } else if (iconName === 'lock') {
      // Do nothing - it's locked
      return;
    }

    // For other actions, pass to the default handler
    handleRowAction(item, iconName);
  };

  // Custom note content row tools component that includes both standard tools and an Add button
  const NoteContentRowTools = ({ rowdata, handleRowAction, handleAddToBody }) => {
    if (!rowdata || !rowdata.id) {
      return null;
    }

    // Determine if we should show delete or lock icon based on whether content is used
    let [iconName, iconColor] = rowdata && (rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited)
      ? ['undo', 'gray.6']
      : ['delete', 'error.4'];

    let $cursor = 'pointer';

    // This is the critical logic that determines whether to show a lock icon or delete icon
    if (rowdata && rowdata.canSafelyDelete === false && !rowdata.isEdited) {
      [iconName, iconColor] = ['lock', 'gray.6'];
      $cursor = 'inherit';
    }

    if (rowdata && rowdata.id && typeof rowdata.id === 'string' &&
        rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
      iconColor = 'gray.2';
    }

    return (
      <Flex alignItems="center">
        <Popup
          trigger={() => (
            <Box $cursor="pointer" onClick={() => handleAddToBody(rowdata.id)}>
              <Icon name="add-circle" color="primary.6" />
            </Box>
          )}
          position="bottom center"
          on={['hover']}
          contentStyle={{ width: 'fit-content' }}
        >
          {'Add note content to body'}
        </Popup>

        <Box
          $cursor={$cursor}
          onClick={() => handleRowAction(rowdata, iconName)}
          style={{ marginLeft: '8px' }}
        >
          <Icon name={iconName} color={iconColor} />
        </Box>
      </Flex>
    );
  };

  const getRowTool = (rowdata) => {
    // Skip rendering if rowdata is missing or has no ID
    if (!rowdata || !rowdata.id) {
      return null;
    }

    // Look for the data in both editedRows and note.noteContents with strict equality
    const data = (editedRows && editedRows.find(row => row && row.id === rowdata.id)) ||
                 (note.noteContents && note.noteContents.find(item => item && item.id === rowdata.id)) ||
                 rowdata; // If not found in either, use the rowdata itself

    if (!data) {
      return null;
    }

    // Define a function to check if a note content is used in the body
    const isNoteContentUsedInBody = (id) => {
      if (!id) return false;

      // First check if it's in the elements structure
      const inElements = elements && elements.length > 0 && elements.some(line =>
        Array.isArray(line) && line.some(element =>
          element && typeof element === 'object' && element.id === id
        )
      );

      // Then check if it's in the raw noteBody string
      const inNoteBody = note.noteBody && note.noteBody.includes(`\${${id}}`);

      // Return true if it's found in either place
      return !!(inElements || inNoteBody);
    };

    // Check if this note content is used in the body
    const isUsedInBody = isNoteContentUsedInBody(rowdata.id);

    // A note content can be safely deleted only if it's not used in the body
    const canSafelyDelete = !isUsedInBody;

    // Create the enhanced rowdata with the canSafelyDelete property
    const enhancedRowData = {
      id: rowdata.id,
      canSafelyDelete,
      isEdited: rowdata.isEdited,
      isCreated: rowdata.isCreated,
      isDeleted: rowdata.isDeleted
    };

    // Find the note content in noteContents to pass to addNoteContentToBody
    const handleAddToBody = (id) => {
      const noteContent = noteContents.find(nc => nc.id === id);
      if (noteContent) {
        addNoteContentToBody(noteContent);
      }
    };

    return (
      <NoteContentRowTools
        rowdata={enhancedRowData}
        handleRowAction={customHandleRowAction}
        handleAddToBody={handleAddToBody}
      />
    );
  };

  const textValueBody = (rowdata, field) => {
    const curRow = editedRows?.find(item => item.id == rowdata.id) ||
                   convertToType(rowdata);

    let valueToDisplay = curRow?.[field];

    if (curRow?.contentType == 'parameter' && field === 'contentDefaultValue') {
      valueToDisplay = parameters.find(p => p.id == valueToDisplay)?.name ?? valueToDisplay;
    }

    // Convert the value to a string for TextCell, handle null/undefined explicitly
    let finalValueString;
    if (valueToDisplay === null || valueToDisplay === undefined) {
      finalValueString = null; // Pass null to let TextCell use placeholder
    } else {
      finalValueString = String(valueToDisplay); // Explicitly convert to string (e.g., 0 -> "0")
    }

    // Pass the string or null to TextCell
    return <TextCell value={finalValueString} placeholder='no default value' />;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleNoteChange = (newBody = false) => {
    setElements(() => {
      const _noteBody = newBody || note.noteBody || '';
      let lines = _noteBody.split('\n');
      let _orderedNoteContents = [];
      let _unusedNoteContents = [];

      // First, find all NoteContents that are in the body
      lines = lines.map(line => {
        if (!line) return [];

        const vals = line.split('${');
        const _items = [];

        vals.forEach(item => {
          if (item && item.includes('}')) {
            const [id, rest] = item.split('}');
            // Look in both local state and original note contents
            let ncVal = noteContents?.find(nc => nc && nc.id === id) ||
                        note.noteContents?.find(nc => nc && nc.id === id);

            // First time we've seen this note content in the body?
            const isFirstOccurrence = !_orderedNoteContents.some(oc => oc.id === id);

            if (typeof (ncVal) === 'object' && ncVal) {
              // Only add to ordered contents if it's the first occurrence
              if (isFirstOccurrence) {
                // Store 0-based index internally
                ncVal = { ...convertToType(ncVal), index: _orderedNoteContents.length };
                _orderedNoteContents.push(ncVal);

              } else {
                // Use the same note content reference for additional occurrences
                ncVal = _orderedNoteContents.find(oc => oc.id === id);
              }

              _items.push(ncVal);
            } else {
              // If not found as an object, just add the placeholder
              _items.push(`\${${id}}`);
            }

            if (rest) {
              _items.push(rest);
            }
          } else {
            _items.push(item);
          }
        });

        return _items;
      });

      // Find NoteContents that are not in the body
      // First get all note contents from the original note that have valid IDs
      const allValidNoteContents = note.noteContents?.filter(nc => nc && nc.id) || [];

      // Then filter out the ones already used in the body
      _unusedNoteContents = allValidNoteContents
        .filter(nc => !_orderedNoteContents.some(usedNc => usedNc.id === nc.id))
        .map((nc, index) => {
          const unusedNc = {
            ...convertToType(nc),
            index: _orderedNoteContents.length + index // Add them after the used ones
          };

          return unusedNc;
        });

      // Combine used and unused NoteContents
      const finalNoteContents = [..._orderedNoteContents, ..._unusedNoteContents];

      // Update noteContents with both used and unused NoteContents
      setNoteContents(finalNoteContents);
      return lines;
    });
  };

  // Initialize note contents when the note changes or dialog opens
  useEffect(() => {
    if (note && note.noteContents && isDialogOpen) {
      // Process both used (in body) and unused note contents
      handleNoteChange(note.noteBody);
    }
  }, [note, isDialogOpen]);

  // Update noteContents when editedRows changes
  useEffect(() => {
    if (editedRows && editedRows.length > 0) {

      setNoteContents(prevNoteContents => {

        // Create a map of edited values for quick lookup
        const editedValuesMap = new Map(editedRows.map(row => [row.id, row.contentDefaultValue]));

        // Update values while preserving order and indices
        const updatedContents = prevNoteContents.map((nc, index) => {
          if (editedValuesMap.has(nc.id)) {
            return { ...nc, contentDefaultValue: editedValuesMap.get(nc.id), index };
          }
          return { ...nc, index };
        });

        return updatedContents;
      });
    }
  }, [editedRows]);

  // Update spans when noteContents changes
  useEffect(() => {
    if (noteContents && noteContents.length > 0) {

      // Create a map of unique note contents first to ensure correct index usage
      const uniqueNoteContents = new Map();
      noteContents.forEach(nc => {
        if (nc && nc.id && !uniqueNoteContents.has(nc.id)) {
          uniqueNoteContents.set(nc.id, nc);
        }
      });

      // For each unique note content, find and update all its instances in the DOM
      uniqueNoteContents.forEach(nc => {
        if (!nc || !nc.id) return;

        const spans = document.querySelectorAll(`span.ncid${nc.id}`);

        if (spans.length > 0) {

          // Determine the display value, handling 0 explicitly
          const value = nc.contentDefaultValue ?? nc.contentValue; // Use ?? to get 0 if it exists
          let displayValueString;
          if (value === null || value === undefined) {
            displayValueString = 'no default value';
          } else {
            displayValueString = String(value); // Convert 0 to "0"
          }

          const displayIndex = nc.index !== undefined ? `${nc.index + 1}: ` : '';
          const newInnerHTML = `${displayIndex}${displayValueString}`;

          // Update each instance
          spans.forEach(span => {

            // Only update if innerHTML actually changed
            if (span.innerHTML !== newInnerHTML) {
              span.innerHTML = newInnerHTML;
              span.setAttribute('data-content-index', nc.index !== undefined ? nc.index : -1);
            }
          });
        }
      });
    }
  }, [noteContents]); // Dependency remains noteContents

  // triggers on create note content to add the span to the unmanaged 'content-editable' div
  useEffect(() => {
    if (noteContentCreated && newNoteContent) {
      // Update local state first
      setNoteContents(_noteContents => ([..._noteContents, newNoteContent]));

      // Call the existing function to add it to the editor body
      addNoteContentToBody(newNoteContent);

      // Reset the creation flag
      resetNoteContentCreated();
    }
  }, [noteContentCreated, newNoteContent, addNoteContentToBody, resetNoteContentCreated]);

  // Handle note content deletion
  useEffect(() => {
    if (noteContentDeleted) {
      // Update noteContents to remove the deleted content
      setNoteContents(prevContents =>
        prevContents.filter(content => content.id !== noteContentDeleted)
      );

      // Update elements to remove references to the deleted content
      handleNoteChange();
    }
  }, [noteContentDeleted]);

  useEffect(() => {
    updateNoteContents(editedRows);
  }, [editedRows]);

  const handleCellEditComplete = (e) => {

    const contentId = e.rowData.id;
    const field = e.field;

    // 1. Call the hook's onCellEditComplete function.
    // It now returns the final state of the edited row.
    let updatedRowData = null;
    try {
      if (onCellEditComplete.current) {
        updatedRowData = onCellEditComplete.current(e);
      }
    } catch (hookError) {
      console.error('Error calling onCellEditComplete hook:', hookError);
    }

    // 2. Update local component state using the data returned by the hook.
    if (updatedRowData) {
      const newValueFromHook = updatedRowData.contentDefaultValue;

      // Update noteContents state
      setNoteContents(prevNoteContents => {
        const updatedContents = prevNoteContents.map(nc => {
          if (nc.id === contentId) {
            return { ...nc, contentDefaultValue: newValueFromHook };
          }
          return nc;
        });

        return updatedContents;
      });

      // Update elements state
      setElements(prevElements => {
        const updatedElements = prevElements.map(line => {
          return line.map(element => {
            if (typeof element === 'object' && element && element.id === contentId) {
              return { ...element, contentDefaultValue: newValueFromHook };
            }
            return element;
          });
        });

        return updatedElements;
      });

      // Update currentNoteContent state
      setCurrentNoteContent(prev => ({
        ...prev,
        contentDefaultValue: newValueFromHook,
        id: contentId,
        index: updatedRowData.index
      }));

    } else {
      // Potentially force a refresh or handle error?
    }

    // Trigger blur handler
    onBlurNoteContent(contentId);
  };

  // --- New Function: Body template for Content Type Icon ---
  const contentTypeIconBody = (rowData) => {
    if (!rowData || !rowData.contentType) {
      return null;
    }

    let iconName = 'help-outline'; // Default icon
    let tooltipText = `Type: ${rowData.contentType}`;

    switch (rowData.contentType) {
    case 'text':
      iconName = 'text-fields';
      break;
    case 'number':
      iconName = 'pin';
      break;
    case 'list':
      iconName = 'list';
      break;
    case 'parameter':
      iconName = 'settings'; // Placeholder icon
      break;
    case 'date':
      iconName = 'calendar-today'; // Placeholder icon
      break;
    default:
      tooltipText = `Unknown Type: ${rowData.contentType}`;
      break;
    }

    return (
      <Popup
        trigger={() => (
          <Box $cursor="default" px={1}> {/* Add some padding */}
            <Icon name={iconName} color="gray.6" />
          </Box>
        )}
        position="bottom center"
        on={['hover']}
        contentStyle={{
          width: 'fit-content',
          padding: '0.25rem 0.5rem',
          fontSize: '0.8rem'
        }} // Adjust style
      >
        {tooltipText}
      </Popup>
    );
  };
  // ------------------------------------------------------

  return (
    <>
      <Flex flexDirection="row" width="100%" style={{ minHeight: '11.5rem' }}>
        {isDialogOpen &&
          <NoteBodyEditor
            value={noteBody}
            noteContents={noteContents}
            originalNoteContents={note.noteContents}
            ref={CERef}
            setNoteContents={setNoteContents}
            elements={elements}
            setElements={setElements}
            parameters={parameters}
            editedRows={editedRows}
            className="note-body-editor"
            lastSelectionRangeRef={lastSelectionRangeRef}
          />
        }
        <NoteContentsListContainer>
          <NoteContentsListHeader className='add-nc-buttons-container'>
            <Popup trigger={() => (
              <Button
                secondary={true}
                className='add-nc-button'
                style={{ margin: '2.5px', width: 'unset' }}
                icon={shouldShowAddButtons ? 'close' : 'add'}
                onClick={() => setShouldShowAddButtons(!shouldShowAddButtons)}
              >
                {shouldShowAddButtons ? '' : 'Create Note Content'}
              </Button>
            )} position='bottom center' on={['hover']} {...{ contentStyle }}>
              {'Add new Note Content to the current note'}
            </Popup>

            <div className='note-content-add-buttons-container' >
              <div className={`note-content-add-buttons ${shouldShowAddButtons ? 'shown' : 'hidden'}`} style={{ height: '2.5rem' }} >
                <Popup trigger={() => (
                  <Button
                    secondary={true}
                    style={{ margin: '2.5px', width: 'unset' }}
                    onClick={() => createNoteContent('text')}
                    icon='text-fields'
                  >
                    Text
                  </Button>
                )} position='bottom center' on={['hover']} {...{ contentStyle }}>
                  {'A sequence of characters and can contain letters, numbers, symbols and spaces.'}<br />
                  {'Example: \'Hello, World!\''}
                </Popup>

                <Popup trigger={() => (
                  <Button
                    secondary={true}
                    style={{ margin: '2.5px', width: 'unset' }}
                    onClick={() => createNoteContent('number')}
                    icon='pin'
                  >
                    Number
                  </Button>
                )} position='bottom center' on={['hover']} {...{ contentStyle }}>
                  {'A numeric value, appearing as a number to CAD users.'}<br />
                  {'Example: 3.14159'}
                </Popup>

                <Popup trigger={() => (
                  <Button
                    secondary={true}
                    style={{ margin: '2.5px', width: 'unset' }}
                    onClick={() => createNoteContent('list')}
                    icon='list'
                  >
                    List
                  </Button>
                )} position='bottom center' on={['hover']} {...{ contentStyle }}>
                  {'A list of selectable text values, appearing as a dropdown to CAD users.'}<br />
                  {'Example: [\'Red\', \'Blue\', \'Green\'].'}
                </Popup>

                {/*
                <Popup trigger={() => (
                  <Button secondary={true} style={{ margin: '2.5px' }} onClick={() => createNoteContent('parameter')}>
                    Parameter
                  </Button>
                )} position='bottom center' on={['hover']} {...{contentStyle}}>
                  {'Choose from a dropdown of your Standardize Parameters. (see parameters page for more information on parameters)'}
                </Popup>
                */}

                {/*
                <Popup trigger={() => (
                  <Button secondary={true} style={{ margin: '2.5px' }} onClick={() => createNoteContent('date')}>
                    Date
                  </Button>
                )} position='bottom center' on={['hover']} {...{contentStyle}}>
                  {'Selectable date value'}
                </Popup>
                */}
              </div>
            </div>
          </NoteContentsListHeader>

          <DataTable
            value={noteContents}
            header={false}
            style={{ width: '100%', height: '100%' }}

            ref={tableRef}

            size='normal'
            editMode='cell'
            rowClassName={(data) => data && rowStyles(data, editedRows)}

            scrollable
            scrollHeight='flex'

            onRowMouseEnter={(e) => { hoverNoteContent(e.data.id); }}
            onRowMouseLeave={(e) => { unhoverNoteContent(e.data.id); }}

            showHeaders={false}
          >
            <Column
              field='index'
              style={{ width: '2rem', textAlign: 'right', paddingRight: '0.5rem' }}
              body={(rowdata) => rowdata.index !== undefined ? `${rowdata.index + 1}:` : ''}
            />
            <Column
              header="Type"
              body={contentTypeIconBody}
              style={{ width: '2.5rem', textAlign: 'center' }}
            />
            <Column
              field='id'
              onBeforeCellEditShow={(e) => {
                onFocusNoteContent(e.rowData);
                onBeforeCellEditShow(e);
              }}
              onCellEditComplete={handleCellEditComplete}
              editor={(e) => (
                <NoteContentEditor
                  currentNoteContent={currentEditingRow}
                  editedRows={editedRows}
                  e={e}
                  onBlurNoteContent={() => tableRef.current.closeEditingCell()}
                  onEditNoteContent={onCellChange}
                  onDropdownComplete={onDropdownComplete}
                />
              )}
              body={(rowdata) => textValueBody(rowdata, 'contentDefaultValue')}
            />
            <Column
              body={(rowdata) => getRowTool(rowdata)}
              style={{ width: '3.5rem' }}
            />
          </DataTable>
        </NoteContentsListContainer>
      </Flex>
    </>
  );
}
