import React from 'react';
import { toast } from 'react-toastify';

/**
 * Creates a Redux middleware that looks for the `notification` property in an
 * action's `meta` property, and then displays and appropriate toast notification
 *
 * @returns {Function} A Redux middleware.
 */
export default function toastifyNotificationMiddleware() {
  return (store: any) =>
    (next: any) =>
      (action: any) => {
        const { notification } = (action || {}).meta || {};

        if (notification && notification.message) {
          const {
            message,
            type = toast.TYPE.DEFAULT,
            position = toast.POSITION.BOTTOM_CENTER,
            ...rest
          } = notification;

          let fullMessage = message;
          if(action.error){
            // sometimes the error message can look a little different depending on the call being made, this prevents new message structures from crashing the web app until we add handling for them here.
            let errorMessage: string | null = null; // Initialize as potentially null
            try {
              // Attempt to extract error string
              const extracted = action.payload.response.data.errors.fullMessages[0].base && action.payload.response.data.errors.fullMessages[0].base[0] || action.payload.response.data.errors.fullMessages[0];
              // Ensure it's actually a string before assigning
              if (typeof extracted === 'string') {
                errorMessage = extracted;
              } else {
                console.warn('Toastify: Extracted error message was not a string:', extracted);
              }
            } catch (error) {
              console.warn('Toastify: failure parsing detailed error message', error);
              // Keep errorMessage as null if parsing fails
            }

            // Only append the error message if it's a valid string
            if (errorMessage) {
              fullMessage = <><div>{message}:</div><div>{errorMessage}</div></>;
            } else {
              // Otherwise, just use the original message (or potentially add a generic error indicator)
              fullMessage = `${message}`;
            }
          }

          toast(fullMessage, {
            type,
            position,
            ...rest,
          });
        }

        next(action);
      };
}
