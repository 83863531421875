import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';
import EK from '../../keys';

import NoteContentSchema, { noteContentSchema } from './schema';
import { denormalize } from 'normalizr';

export const selectNormalizedNoteContents = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.NOTE_CONTENTS.state)
);

export const selectCurrentNoteContents = (noteId) => createSelector(
  selectEntities(),
  selectNormalizedNoteContents(),
  (entities, noteContents) => noteContents && denormalize(noteContents.filter( nc => nc.note === noteId ), [NoteContentSchema], entities)?.toList().toArray() || []
);

const selectNoteContentsModalState = () => createSelector(
  state => state.get('noteContentsModal'),
  ncstate => ncstate
);

export const selectNoteContentsState = () => createSelector(
  selectNoteContentsModalState(),
  (ncstate) => ({  
    noteContentCreated: ncstate.get('noteContentCreated'), 
    newNoteContent: ncstate.get('newNoteContent'), 
    noteContentsLoading: ncstate.get('noteContentsLoading'), 
    noteContentDeleted: ncstate.get('noteContentDeleted'),
  })
);
