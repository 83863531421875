import React from 'react';

import Text from '../../common/Text';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ListCreatorEditor from '../ListCreatorEditor';

/**
 * Renders a cell editor based on the value type of the current editing row.
 *
 * @param {Object} currentEditingRow - The current editing row object.
 * @param {Function} handleEditedRowChange - The function to handle changes in the edited row.
 * @param {Array} units - The array of units for the dropdown.
 * @returns {JSX.Element} The rendered cell editor component.
 */
function WITH_EDITABLE_UNITS(currentEditingRow, handleEditedRowChange, units) {

  switch (currentEditingRow.valueType) {
  case 'integer':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>An integer is a whole number, or a number with no decimal digits. example: 31</Text>
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <InputNumber id='defaultValue' value={currentEditingRow.defaultValue} onChange={(e) => handleEditedRowChange(e.value, 'defaultValue')} maxFractionDigits={0} />
      </div>
    );
  case 'bool':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>A boolean can be either true or false.</Text>
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <Dropdown
          value={currentEditingRow.defaultValue}
          options={[{label: 'true', value: 'true'},{label: 'false', value: 'false'}, {label: 'undefined', value: null}]}
          filter={true}

          optionLabel='label'
          optionValue='value'

          onChange={(e) => handleEditedRowChange(e.value, 'defaultValue', currentEditingRow)}
          style={{ width: '100%' }}
        />
      </div>
    );
  case 'string':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6' >A string is a sequence of characters and can contain letters, numbers, symbols and spaces. example: &apos;Hello, World!&apos;</Text>
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <InputText id='defaultValue' value={currentEditingRow.defaultValue} onChange={(e) => handleEditedRowChange(e.target.value, 'defaultValue')} />
      </div>
    );
  case 'double':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>A double is similar to an integer but can contain decimal digits. example: 1.001</Text>
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <InputNumber id='defaultValue' value={currentEditingRow.defaultValue} onChange={(e) => handleEditedRowChange(e.value, 'defaultValue')} minFractionDigits={2} maxFractionDigits={5} style={{ width: '90%', float: 'left' }} />
        <Dropdown
          optionLabel='abbreviation'
          value={currentEditingRow.unit}
          options={units}
          onChange={(e) => handleEditedRowChange(e.target.value, 'unit')}
          style={{ width: '9%', float: 'right' }}
        />
      </div>
    );
  case 'list':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>A list of multi-character values</Text>
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <ListCreatorEditor
          value={currentEditingRow.listValues}
          defaultValue={currentEditingRow.defaultValue}
          onChange={(newValue) => handleEditedRowChange(newValue, 'listValues')}
          onDefaultValueChange={(newValue) => handleEditedRowChange(newValue, 'defaultValue')}
          minListSize={2}
          maxListSize={100}
        />
      </div>
    );
  default:
    return (
      <div className='field' >
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <Text fontSize={1} color='gray.6'>Choose value type</Text>
      </div>
    );
  }
}

function WITHOUT_EDITABLE_UNITS(currentEditingRow, handleEditedRowChange) {

  switch (currentEditingRow.valueType) {
  case 'integer':
    return (
      <div className='field' style={{ paddingBottom: '5px' }}>
        <Text fontSize={1} color='gray.6'>
          An integer is a whole number, or a number with no decimal digits. example: 31
        </Text>
        <Text $ellipsis fontSize={2} color='gray.7'>
          Default Value
        </Text>
        <InputNumber
          id='defaultValue'
          value={currentEditingRow.defaultValue}
          onChange={(e) => handleEditedRowChange(e.value, 'defaultValue')}
          maxFractionDigits={0}
        />
      </div>
    );

  case 'bool':
    return (
      <div className='field' style={{ paddingBottom: '5px' }}>
        <Text fontSize={1} color='gray.6'>
          A boolean can be either true or false.
        </Text>
        <Text $ellipsis fontSize={2} color='gray.7'>
          Default Value
        </Text>
        <InputSwitch
          id='defaultValue'
          checked={currentEditingRow.defaultValue}
          onChange={(e) => handleEditedRowChange(e.target.value, 'defaultValue')}
          trueValue={'true'}
          falseValue={'false'}
          style={{ marginLeft: '20px' }}
        />
      </div>
    );

  case 'string':
    return (
      <div className='field' style={{ paddingBottom: '5px' }}>
        <Text fontSize={1} color='gray.6'>
          A string is a sequence of characters and can contain letters, numbers, symbols and spaces. example: &apos;Hello, World!&apos;
        </Text>
        <Text $ellipsis fontSize={2} color='gray.7'>
          Default Value
        </Text>
        <InputText
          id='defaultValue'
          value={currentEditingRow.defaultValue}
          onChange={(e) => handleEditedRowChange(e.target.value, 'defaultValue')}
        />
      </div>
    );

  case 'double':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>
          A double is similar to an integer but can contain decimal digits. example: 1.001
        </Text>
        <Text $ellipsis fontSize={2} color='gray.7'>
          Default Value
        </Text>
        <InputNumber
          id='defaultValue'
          value={currentEditingRow.defaultValue}
          onChange={(e) => handleEditedRowChange(e.value, 'defaultValue')}
          minFractionDigits={2}
          maxFractionDigits={5}
          style={{ width: '90%', float: 'left' }}
        />
        <Dropdown
          optionLabel='abbreviation'
          value={currentEditingRow.unit}
          options={units}
          onChange={(e) => handleEditedRowChange(e.target.value, 'unit')}
          style={{ width: '9%', float: 'right' }}
        />
      </div>
    );

  case 'list':
    return (
      <div className='field' style={{ paddingBottom: '5px' }} >
        <Text fontSize={1} color='gray.6'>
          A list of multi-character values
        </Text>
        <Text $ellipsis fontSize={2} color='gray.7'>
          Default Value
        </Text>
        <ListCreatorEditor
          value={currentEditingRow.listValues}
          defaultValue={currentEditingRow.defaultValue}
          onChange={(newValue) => handleEditedRowChange(newValue, 'listValues')}
          onDefaultValueChange={(newValue) => handleEditedRowChange(newValue, 'defaultValue')}
          minListSize={2}
          maxListSize={100}
        />
      </div>
    );

  default:
    return (
      <div className='field' >
        <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
        <Text fontSize={1} color='gray.6'>Choose value type</Text>
      </div>
    );
  }
}

export default function (currentEditingRow, handleEditedRowChange, units, useUnits=true) {
  return useUnits && WITH_EDITABLE_UNITS(currentEditingRow, handleEditedRowChange, units) || WITHOUT_EDITABLE_UNITS(currentEditingRow, handleEditedRowChange);
}