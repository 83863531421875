import React from 'react';

import Icon from '../../Icon';
import Box from '../../Box';

/**
 * Component that displays either 'undo', 'delete', or 'lock' based on the isEdited, isCreated, isDeleted properties of the rowdata
 * and handles clicks based on the current icon displayed.
 */
const EntityRowTools = React.forwardRef(({ rowdata, handleRowAction, ...props }, ref) => {
  if (!rowdata) {
    return null;
  }

  let [iconName, iconColor] = rowdata && (rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited)
    ? ['undo', 'gray.6']
    : ['delete', 'error.4'];

  let $cursor = 'pointer';

  // This is the critical logic that determines whether to show a lock icon or delete icon
  // If canSafelyDelete is explicitly false and not edited, show lock icon
  if (rowdata && rowdata.canSafelyDelete === false && !rowdata.isEdited) {
    [iconName, iconColor] = ['lock', 'gray.6'];
    $cursor = 'inherit';
  }

  if (rowdata && rowdata.id && typeof rowdata.id === 'string' && rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
    iconColor = 'gray.2';
  }

  return (
    <Box
      ref={ref}
      $cursor={$cursor}
      onClick={() => handleRowAction(rowdata, iconName)}
      {...props}
    >
      <Icon name={iconName} color={iconColor} />
    </Box>
  );
});

export default EntityRowTools;