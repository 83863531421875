import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { NOTE_CONTENT_CREATED, NOTE_CONTENT_CREATED_ERROR, NOTE_CONTENTS_LOADED, NOTE_CONTENT_DELETED } from './constants';

import EK from '../../../entities/keys';

import {
  createNoteContentTypes,
  deleteNoteContentTypes,
} from '../../../entities/Standardize/NoteContents/constants';
import { saveNotes } from '../../../entities/Standardize/Notes/constants';

function* handleCreateNoteContentSuccess({ type, payload = {} }) {
  const noteContent = get(payload, `${EK.NOTE_CONTENTS.plural}[0]`);
  if (noteContent?.id) {
    yield put({ type: NOTE_CONTENT_CREATED, payload: noteContent });
  }
  yield put({ type: NOTE_CONTENT_CREATED_ERROR });
}

function* handleDeleteNoteContentSuccess({ type, payload = {} }) {
  const noteContent = get(payload, `${EK.NOTE_CONTENTS.single}`);
  if (noteContent?.id) {
    yield put({ type: NOTE_CONTENT_DELETED, payload: noteContent });
  }
}

function* watchCreateNoteContentSaga() {
  yield takeLatest([
    createNoteContentTypes.SUCCESS,
  ], handleCreateNoteContentSuccess
  );
}

function* watchDeleteNoteContentSaga() {
  yield takeLatest([
    deleteNoteContentTypes.SUCCESS,
  ], handleDeleteNoteContentSuccess
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateNoteContentSaga),
    fork(watchDeleteNoteContentSaga),
  ]);
}
